import { Box } from "@mui/system";
import { Slide } from "@mui/material";
import careivroom from "../assets/careivroom.png";
import { useEffect, useState } from "react";

let timerId = 0;

const Header: React.FC = () => {
  const [slideIn, setSlideIn] = useState<boolean>(false);

  useEffect(() => {
    timerId = window.setTimeout(() => {
      setSlideIn(true);
    }, 250);

    return () => {
      window.clearInterval(timerId);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingTop: 5,
      }}
    >
      <Slide direction='right' in={slideIn}>
        <img alt='' src={careivroom} height={50} />
      </Slide>
    </Box>
  );
};

export default Header;
