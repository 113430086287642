const axios = require("axios");

export const getText = async (): Promise<string> => {
  let result = axios
    .get(
      "https://random-data-api.com/api/hipster/random_hipster_stuff?ID=12345"
    )
    .then((res) => {
      let text = "";
      res.data.paragraphs.forEach((p) => {
        text = text.concat(p + " ");
      });
      text.trim();

      return text;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

export const getWords = async (): Promise<string[]> => {
  let result = axios
    .get(
      "https://random-data-api.com/api/hipster/random_hipster_stuff?ID=12345"
    )
    .then((res) => {
      let text = "";
      res.data.paragraphs.forEach((p) => {
        text = text.concat(p + " ");
      });
      text.trim();
      return text;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};
