import React from "react";
import { Box } from "@mui/material";
import SlideRace from "./components/SlideRace";
import TypeRace from "./components/TypeRace";
import Header from "./components/Header";
import Home from "./components/Home";
import "typeface-roboto";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          maxHeight: "100vh",
        }}
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path='/sliderace' element={<SlideRace />} />
          <Route path='/typerace' element={<TypeRace />} />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}

export default App;
