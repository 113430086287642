import { Button, Slider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { Client, Message } from "paho-mqtt";
import ReactSpeedometer from "react-d3-speedometer";

const client = new Client("need-for-speed.cloud.shiftr.io", 443, "/", "react-app");

const KALIX_A_TOPIC = "kalix/a";
const KALIX_B_TOPIC = "kalix/b";
const MALMO_A_TOPIC = "malmo/a";
const MALMO_B_TOPIC = "malmo/b";

const SlideRace: React.FC = () => {
  const [track, setTrack] = useState<string | undefined>();
  const [throttle, setThrottle] = useState<number | undefined>(0);
  const [connected, setConnected] = useState(false);
  const [topic, setTopic] = useState<string | undefined>();

  const [speedKalixA, setSpeedKalixA] = useState<number>(0);
  const [speedKalixB, setSpeedKalixB] = useState<number>(0);
  const [speedMalmoA, setSpeedMalmoA] = useState<number>(0);
  const [speedMalmoB, setSpeedMalmoB] = useState<number>(0);
  const [remoteThrottle, setRemoteThrottle] = useState<number>(0);

  const onConnect = () => {
    console.log("Successful connect!");
    setConnected(true);

    client.subscribe("#");
  };

  useEffect(() => {
    client.connect({
      onSuccess: onConnect,
      useSSL: true,
      userName: "need-for-speed",
      password: "Qo7h3F9ZejnTq1uA",
    });
    client.onMessageArrived = (message: Message) => {
      const payload = message.payloadString;
      const speedValue = parseInt(payload.slice(1, payload.length));

      switch (message.destinationName) {
        case KALIX_A_TOPIC:
          setSpeedKalixA(speedValue);
          break;
        case KALIX_B_TOPIC:
          setSpeedKalixB(speedValue);
          break;
        case MALMO_A_TOPIC:
          setSpeedMalmoA(speedValue);
          break;
        case MALMO_B_TOPIC:
          setSpeedMalmoB(speedValue);
          break;
      }
    };
  }, []);

  // Kind of ugly way to do this
  useEffect(() => {
    switch (topic) {
      case KALIX_A_TOPIC:
        setRemoteThrottle(speedKalixA);
        break;
      case KALIX_B_TOPIC:
        setRemoteThrottle(speedKalixB);
        break;
      case MALMO_A_TOPIC:
        setRemoteThrottle(speedMalmoA);
        break;
      case MALMO_B_TOPIC:
        setRemoteThrottle(speedMalmoB);
        break;
    }
  }, [topic, speedKalixA, speedKalixB, speedMalmoA, speedMalmoB]);

  useEffect(() => {
    //Do stuff
    if (connected) {
      console.log("Throttle:", throttle);
      console.log("Track:", track);
      if (
        throttle !== undefined &&
        track !== undefined &&
        topic !== undefined
      ) {
        const message = new Message(`${track}${throttle}`);
        message.destinationName = topic;
        message.retained = true;
        client.send(message);
      }
    }
  }, [track, throttle, topic, connected]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setThrottle(newValue as number);
  };

  const handleDisconnect = () => {
    if (connected) {
      if (
        throttle !== undefined &&
        track !== undefined &&
        topic !== undefined
      ) {
        const message = new Message(`${track}${0}`);
        message.destinationName = topic;
        message.retained = true;
        client.send(message);
        setTopic(undefined);
        setTrack(undefined);
        setThrottle(0);
      }
    }
  };

  const setTrackAndTopic = (newTopic: string) => {
    setTrack(newTopic.split("/")[1]);
    setTopic(newTopic);
  };

  return (
    <Box
      sx={{
        marginTop: 10,
        height: 500,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography>Select a track</Typography>

      <Stack direction='row' spacing={1}>
        <SpeedButton
          title={"Kalix A"}
          speedValue={speedKalixA}
          onClick={() => setTrackAndTopic(KALIX_A_TOPIC)}
        />
        <SpeedButton
          title={"Kalix B"}
          speedValue={speedKalixB}
          onClick={() => setTrackAndTopic(KALIX_B_TOPIC)}
        />
        <SpeedButton
          title={"Malmö A"}
          speedValue={speedMalmoA}
          onClick={() => setTrackAndTopic(MALMO_A_TOPIC)}
        />
        <SpeedButton
          title={"Malmö B"}
          speedValue={speedMalmoB}
          onClick={() => setTrackAndTopic(MALMO_B_TOPIC)}
        />
      </Stack>

      {track && topic && (
        <Box width={500}>
          <Typography fontSize={22}>
            {topic.toUpperCase().replace("/", " - ").replace("O", "Ö")}
          </Typography>
          <Typography fontSize={22}>
            Power: {remoteThrottle} gigajolts
          </Typography>
          <Slider
            value={remoteThrottle}
            sx={{ width: 1 }}
            min={10}
            max={250}
            onChange={handleChange}
          />
          <Button onClick={handleDisconnect}>Disconnect</Button>

          <video
            width='500'
            height='500'
            loop={true}
            autoPlay={true}
            preload='auto'
            playsInline={true}
            style={{ maxWidth: "500px" }}
          >
            <source src='/speedracer.mp4' type='video/mp4' />
          </video>
        </Box>
      )}
    </Box>
  );
};

const SpeedButton: React.FC<{
  speedValue: number;
  title: string;
  onClick: () => void;
}> = ({ speedValue, title, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactSpeedometer
        width={150}
        height={100}
        maxValue={255}
        value={speedValue}
        ringWidth={20}
        needleColor='black'
        startColor='#00e676'
        segments={5}
        maxSegmentLabels={0}
        needleHeightRatio={0.6}
        textColor='transparent'
        valueTextFontSize='1'
        endColor='#ff1744'
      />
      <Typography variant='h5'>{speedValue + " gjs"}</Typography>
      <Button onClick={() => onClick()}>{title}</Button>
    </Box>
  );
};

export default SlideRace;
