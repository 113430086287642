import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 500,
      }}
    >
      <Typography fontSize={22}>Select mode</Typography>

      <Button onClick={() => navigate("/sliderace")}>Slide racing</Button>
      <Button onClick={() => navigate("/typerace")}>Type racing</Button>
    </Box>
  );
};

export default Home;
