import {
  formHelperTextClasses,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useRef } from "react";
import "typeface-roboto";
import { Client, Message } from "paho-mqtt";
import { inherits } from "util";
import { getText } from "../api/text";

const client = new Client("need-for-speed.cloud.shiftr.io", 443, "/", "react-app");

const TypeRace: React.FC = () => {
  const [input, setInput] = useState("");
  const [hasError, setHasError] = useState(false);
  const [record, setRecord] = useState(0);
  const [connected, setConnected] = useState(false);
  const [timer, setTimer] = useState(0);
  const [track, setTrack] = useState("malmo/a");
  const [text, setText] = useState("");

  const handleChange = (str) => {
    setInput(str);
  };

  const onConnect = () => {
    console.log("Successful connect!");
    setConnected(true);
  };

  const init = async () => {
    const _text = await getText();
    setText(_text);
  };

  useEffect(() => {
    init();
    client.connect({
      onSuccess: onConnect,
      useSSL: true,
      userName: "need-for-speed",
      password: "Qo7h3F9ZejnTq1uA",
    });
  }, []);

  useEffect(() => {
    var _hasError = false;
    for (var i = 0; i < input.length; i++) {
      if (input[i] !== text[i]) {
        _hasError = true;
      }
    }
    setHasError(_hasError);
    if (!_hasError && input.length > record) {
      setRecord(input.length);
    }
  }, [input, text, record]);

  const stopCar = () => {
    window.clearTimeout(timer);
    var int = window.setTimeout(() => {
      let message = new Message(`${track.substr(track.length - 1)}0`);
      message.retained = true;
      message.destinationName = track;
      client.send(message);
    }, 500);

    setTimer(int);
  };

  useEffect(() => {
    if (connected && record > 0) {
      let message = new Message(`${track.substr(track.length - 1)}150`);
      message.retained = true;

      message.destinationName = track;
      client.send(message);
      stopCar();
    }
  }, [record, connected]);

  const handleMenuChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setTrack(value);
  };

  return (
    <>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        <Select
          defaultValue={"malmo/a"}
          onChange={handleMenuChange}
          sx={{ marginBottom: 3 }}
        >
          <MenuItem value={"malmo/a"}>Malmö track A</MenuItem>
          <MenuItem value={"malmo/b"}>Malmö track B</MenuItem>
          <MenuItem value={"kalix/a"}>Kalix track A</MenuItem>
          <MenuItem value={"kalix/b"}>Kalix track B</MenuItem>
        </Select>
        <Typography fontSize={22}>Type to drive!</Typography>
      </Box>
      <Box sx={{ position: "relative", width: "50%" }}>
        <Input
          multiline
          readOnly
          sx={{ width: "100%", position: "absolute" }}
          placeholder={text}
          disableUnderline
          minRows={10}
        />
        <Input
          multiline
          disableUnderline
          minRows={10}
          value={input}
          sx={{ width: "100%", color: hasError ? "red" : "green" }}
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{ spellCheck: false }}
        />
      </Box>
    </>
  );
};

export default TypeRace;
